<p-toast position="top-right"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [rejectVisible]="false"></p-confirmDialog>
<div class="auth-page">
    <div class="container">
        <div class="row">
            <div class=" centered-card">
                <div class="card shadow">
                    <div class="card-header">
                        <img src="../../../../assets/images/Automatrwhite.svg">
                    </div>
                    <div class="card-body">
                        <div class="container">
                            <h5 class="card-title">Register Account</h5>
                            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-xxl-6 col-lg-6 col-md-6">
                                        <div class="card card-h-100">
                                            <div class="card-body">
                                                <div class="d-flex flex-wrap align-items-center mb-3">
                                                    <h5 class="card-title me-2">Company</h5>
                                                    <div class="group-button margin-left">
                                                        <button class="btn-soft-primary btn-sm"
                                                            (click)="onCopyToContact()" type="button">Copy to
                                                            Contact</button>
                                                    </div>
                                                </div>


                                                <div class="row align-items-center">
                                                    <div class="col-sm">
                                                        <div class="mb-3">
                                                            <label for="email">User Name/Email Id <span
                                                                    class="required">*</span></label>
                                                            <input type="email" formControlName="email"
                                                                class="form-control" id="email"
                                                                placeholder="Enter Email"
                                                                (change)="onFinalUserName($event.target.value)">

                                                            <span *ngIf="showErrorAlready" class="required">User
                                                                already exists! Please Go to Login</span>
                                                            <span
                                                                *ngIf="formSubmitted && registerForm.get('email').hasError('required')"
                                                                class="required">Email is required.</span>
                                                            <span
                                                                *ngIf="formSubmitted && registerForm.get('email').hasError('email')"
                                                                class="required">Please enter a valid email
                                                                address.</span>
                                                        </div>

                                                        <div class="mb-3">
                                                            <label for="companyName">Name <span
                                                                    class="required">*</span></label>
                                                            <input type="text" formControlName="companyName"
                                                                class="form-control" id="companyName"
                                                                placeholder="Enter Company Name">
                                                            <span
                                                                *ngIf="formSubmitted && registerForm.get('companyName').hasError('required')"
                                                                class="required">Company Name is required.</span>
                                                        </div>

                                                        <div class="mb-3">
                                                            <label for="companyURL">Website <span
                                                                    class="required">*</span></label>
                                                            <input type="text" formControlName="companyURL"
                                                                class="form-control" id="companyURL"
                                                                placeholder="Enter URL">
                                                            <span
                                                                *ngIf="formSubmitted && registerForm.get('companyURL').hasError('required')"
                                                                class="required">Company URL is
                                                                required.</span>
                                                            <span
                                                                *ngIf="formSubmitted && registerForm.get('companyURL').hasError('pattern')"
                                                                class="required">Please enter a valid website
                                                                URL.</span>
                                                        </div>

                                                        <div class="mb-3">
                                                            <label for="phone">Phone Number <span
                                                                    class="required">*</span></label><br>
                                                            <ngx-intl-tel-input [enableAutoCountrySelect]="true"
                                                                [searchCountryFlag]="true"
                                                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField?.Name]"
                                                                [selectFirstCountry]="false" [maxLength]="15"
                                                                [phoneValidation]="true" [separateDialCode]="true"
                                                                [enablePlaceholder]="true" #phoneNumberInput
                                                                customPlaceholder=" Phone Number" name="phone"
                                                                formControlName="phone">
                                                            </ngx-intl-tel-input><br>
                                                            <span
                                                                *ngIf="checkPhone && this.registerForm.controls['phone'].invalid"
                                                                class="required"> Please
                                                                provide a valid phone number as
                                                                it is required for further assistance</span>
                                                        </div>
                                                        <div class="mb-3">
                                                            <label for="country">Country <span
                                                                    class="required">*</span></label>
                                                            <select formControlName="country" class="form-control"
                                                                id="country">
                                                                <option value="">Select a country</option>
                                                                <option [value]="country.common"
                                                                    *ngFor="let country of countryName">
                                                                    {{country.common}}</option>
                                                            </select>
                                                            <span
                                                                *ngIf="formSubmitted && registerForm.get('country').hasError('required')"
                                                                class="required">Country is required.</span>
                                                        </div>

                                                        <div class="mb-3">
                                                            <label for="address">Company Address </label>
                                                            <textarea formControlName="address" class="form-control"
                                                                id="address" placeholder="Enter Contact Address">
                                           </textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xxl-6 col-lg-6 col-md-6">
                                        <div class="card card-h-100">
                                            <div class="card-body">
                                                <div class="d-flex flex-wrap align-items-center mb-4">
                                                    <h5 class="card-title me-2">Contact</h5>

                                                </div>

                                                <div class="row align-items-center">
                                                    <div class="col-sm">
                                                        <div class="mb-3">
                                                            <label for="contactName">Name <span
                                                                    class="required">*</span></label>
                                                            <input type="text" formControlName="contactName"
                                                                class="form-control" id="contactName"
                                                                placeholder="Enter Contact Name">
                                                            <span
                                                                *ngIf="formSubmitted && registerForm.get('contactName').hasError('required')"
                                                                class="required">Contact Name is required.</span>
                                                        </div>

                                                        <div class="mb-3">
                                                            <label for="contactEmail">Email <span
                                                                    class="required">*</span></label>
                                                            <input type="email" formControlName="contactEmail"
                                                                class="form-control" id="contactEmail"
                                                                placeholder="Enter Contact Email">
                                                            <span
                                                                *ngIf="formSubmitted && registerForm.get('contactEmail').hasError('required')"
                                                                class="required">Contact Email is required.</span>
                                                            <span
                                                                *ngIf="formSubmitted && registerForm.get('contactEmail').hasError('email')"
                                                                class="required">Please enter a valid email
                                                                address.</span>


                                                        </div>

                                                        <div class="mb-3">
                                                            <label for="contactPhone">Phone Number <span
                                                                    class="required">*</span></label>
                                                            <ngx-intl-tel-input [enableAutoCountrySelect]="true"
                                                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField?.Name]"
                                                                [selectFirstCountry]="false" [maxLength]="15"
                                                                [phoneValidation]="true" [separateDialCode]="true"
                                                                [enablePlaceholder]="true"
                                                                customPlaceholder=" Contact Number" name="contactPhone"
                                                                formControlName="contactPhone">
                                                            </ngx-intl-tel-input><br>
                                                            <span
                                                                *ngIf="checkContactPhone && this.registerForm.controls['contactPhone'].invalid"
                                                                class="required"> Please
                                                                provide a valid phone
                                                                number as it is required for further
                                                                assistance</span>

                                                        </div>
                                                        <div class="mb-3">
                                                            <div class="mt-4 group-button">
                                                                <button class="btn btn-soft-primary me-2"
                                                                    (click)="goHome()" type="button">
                                                                    Go to Home Page
                                                                </button>
                                                                <button class="btn btn-primary" type="submit">
                                                                    Proceed
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="container p-0">
        <div class="row g-0">
            <div>
                <div class="auth-full-page-content p-sm-5 p-4">
                    <div class="w-100">
                        <div class="d-flex flex-column h-100">
                            <div class="mb-4 mb-md-5 text-center">
                                <a routerLink="/" class="d-block auth-logo">
                                    <span class="logo-txt">AutomatR</span>
                                </a>
                            </div>
                            <div class="auth-content my-auto">
                                <div class="text-center">
                                    <h5 class="mb-0">Register Account</h5>
                                    <p class="text-muted mt-2">Get your AutomatR account.</p>
                                </div>
                                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-xxl-6 col-lg-6 col-md-6">
                                            <div class="card card-h-100">
                                                <div class="card-body">
                                                    <div class="d-flex flex-wrap align-items-center mb-4">
                                                        <h5 class="card-title me-2">Company</h5>
                                                        <div class="group-button">
                                                            <button class="btn-soft-primary btn-sm">Copy to
                                                                Contact</button>
                                                        </div>
                                                    </div>

                                                    <div class="row align-items-center">
                                                        <div class="col-sm">
                                                            <div class="mb-3">
                                                                <label for="email">User Name/Email Id <span
                                                                        class="required">*</span></label>
                                                                <input type="email" formControlName="email"
                                                                    class="form-control" id="email"
                                                                    placeholder="Enter Email"
                                                                    (change)="onFinalUserName($event.target.value)">

                                                                <span *ngIf="showErrorAlready" class="required">User
                                                                    already exists! Please Go to Login</span>
                                                                <span
                                                                    *ngIf="formSubmitted && registerForm.get('email').hasError('required')"
                                                                    class="required">Email is required.</span>
                                                                <span
                                                                    *ngIf="formSubmitted && registerForm.get('email').hasError('email')"
                                                                    class="required">Please enter a valid email
                                                                    address.</span>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="companyName">Name <span
                                                                        class="required">*</span></label>
                                                                <input type="text" formControlName="companyName"
                                                                    class="form-control" id="companyName"
                                                                    placeholder="Enter Company Name">
                                                                <span
                                                                    *ngIf="formSubmitted && registerForm.get('companyName').hasError('required')"
                                                                    class="required">Company Name is required.</span>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="companyURL">Website <span
                                                                        class="required">*</span></label>
                                                                <input type="text" formControlName="companyURL"
                                                                    class="form-control" id="companyURL"
                                                                    placeholder="Enter URL">
                                                                <span
                                                                    *ngIf="formSubmitted && registerForm.get('companyURL').hasError('required')"
                                                                    class="required">Company URL is
                                                                    required.</span>
                                                                <span
                                                                    *ngIf="formSubmitted && registerForm.get('companyURL').hasError('pattern')"
                                                                    class="required">Please enter a valid website
                                                                    URL.</span>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="phone">Phone Number <span
                                                                        class="required">*</span></label><br>
                                                                <ngx-intl-tel-input [enableAutoCountrySelect]="true"
                                                                    [searchCountryFlag]="true"
                                                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField?.Name]"
                                                                    [selectFirstCountry]="false" [maxLength]="15"
                                                                    [phoneValidation]="true" [separateDialCode]="true"
                                                                    [enablePlaceholder]="true" #phoneNumberInput
                                                                    customPlaceholder=" Phone Number" name="phone"
                                                                    formControlName="phone">
                                                                </ngx-intl-tel-input><br>
                                                                <span
                                                                    *ngIf="checkPhone && this.registerForm.controls['phone'].invalid"
                                                                    class="required"> Please
                                                                    provide a valid phone number as
                                                                    it is required for further assistance</span>
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="country">Country <span
                                                                        class="required">*</span></label>
                                                                <select formControlName="country" class="form-control"
                                                                    id="country">
                                                                    <option value="">Select a country</option>
                                                                    <option [value]="country.common"
                                                                        *ngFor="let country of countryName">
                                                                        {{country.common}}</option>
                                                                </select>
                                                                <span
                                                                    *ngIf="formSubmitted && registerForm.get('country').hasError('required')"
                                                                    class="required">Country is required.</span>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="address">Company Address </label>
                                                                <textarea formControlName="address" class="form-control"
                                                                    id="address" placeholder="Enter Contact Address">
                                                           </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xxl-6 col-lg-6 col-md-6">
                                            <div class="card card-h-100">
                                                <div class="card-body">
                                                    <div class="d-flex flex-wrap align-items-center mb-4">
                                                        <h5 class="card-title me-2">Contact</h5>

                                                    </div>

                                                    <div class="row align-items-center">
                                                        <div class="col-sm">
                                                            <div class="mb-3">
                                                                <label for="contactName">Name <span
                                                                        class="required">*</span></label>
                                                                <input type="text" formControlName="contactName"
                                                                    class="form-control" id="contactName"
                                                                    placeholder="Enter Contact Name">
                                                                <span
                                                                    *ngIf="formSubmitted && registerForm.get('contactName').hasError('required')"
                                                                    class="required">Contact Name is required.</span>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="contactEmail">Email <span
                                                                        class="required">*</span></label>
                                                                <input type="email" formControlName="contactEmail"
                                                                    class="form-control" id="contactEmail"
                                                                    placeholder="Enter Contact Email">
                                                                <span
                                                                    *ngIf="formSubmitted && registerForm.get('contactEmail').hasError('required')"
                                                                    class="required">Contact Email is required.</span>
                                                                <span
                                                                    *ngIf="formSubmitted && registerForm.get('contactEmail').hasError('email')"
                                                                    class="required">Please enter a valid email
                                                                    address.</span>


                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="contactPhone">Phone Number <span
                                                                        class="required">*</span></label>
                                                                <ngx-intl-tel-input [enableAutoCountrySelect]="true"
                                                                    [enablePlaceholder]="true"
                                                                    [searchCountryFlag]="true"
                                                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField?.Name]"
                                                                    [selectFirstCountry]="false" [maxLength]="15"
                                                                    [phoneValidation]="true" [separateDialCode]="true"
                                                                    [enablePlaceholder]="true"
                                                                    customPlaceholder=" Contact Number" name="phone"
                                                                    formControlName="contactPhone">
                                                                </ngx-intl-tel-input><br>
                                                                <span
                                                                    *ngIf="checkContactPhone && this.registerForm.controls['contactPhone'].invalid"
                                                                    class="required"> Please
                                                                    provide a valid phone
                                                                    number as it is required for further
                                                                    assistance</span>

                                                            </div>
                                                            <div class="mb-3">
                                                                <div class="mt-4 group-button">
                                                                    <button class="btn btn-soft-primary me-2"
                                                                        (click)="goHome()" type="button">
                                                                        Go to Home Page
                                                                    </button>
                                                                    <button class="btn btn-primary" type="submit">
                                                                        Proceed
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>