import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login/login.component';
import { RegisterComponent } from './components/register/register/register.component';
import { OnetimepasswordComponent } from './components/register/onetimepassword/onetimepassword.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { InputTextModule } from 'primeng/inputtext';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ReactiveFormsModule } from '@angular/forms';


import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { PaginatorModule } from 'primeng/paginator';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { MessageModule } from 'primeng/message';
import { TooltipModule } from 'primeng/tooltip';
import { InterceptorService, LoaderService } from './core/interceptor/interceptor';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthService } from './oidc/oauth/oauth.service';
import { SuperAdminComponent } from './components/super-admin/super-admin.component';
import { AccountAdminComponent } from './components/account-admin/account-admin.component';
import { Globals } from './globals/globals';
import { AuthguardGuard } from 'src/app/core/guards/authguard.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PasswordModule } from 'primeng/password';

import { NgbNavModule, NgbPaginationModule, NgbTypeaheadModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AvatarModule } from 'primeng/avatar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from "primeng/dialog"; 





@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    OnetimepasswordComponent,
    ForgotpasswordComponent,
    SuperAdminComponent,
    AccountAdminComponent,

  ],
  imports: [
    AvatarModule,
    HttpClientModule,
    NgxOtpInputModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InputTextModule,
    ReactiveFormsModule,
    TooltipModule,
    MessageModule,
    ToastModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    SidebarModule,
    PasswordModule,
    NgbNavModule,
    NgbModule,
    NgbDropdownModule,
    CheckboxModule,
    NgxIntlTelInputModule,
    ConfirmDialogModule,
    DialogModule,
    OAuthModule.forRoot(),
    NgxSpinnerModule.forRoot()
  ],
  providers: [
    AuthService,
    Globals,
    AuthguardGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderService,
      multi: true
    },

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
