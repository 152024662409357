import { Injectable } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';

@Injectable()
export class Globals {
    public isPlatformRole : boolean;
    public TenantUniqueKey: string = '';
    public userData: any;
	public orchestratorURL: string = '';
    public WorkSpaceID: string = '';
    public workspaceOptions: any[] = null;
    public workspaceActions: any[] = [];
	
	public spinnerAnimation:boolean = true;
	
	public activeSidebars: any[] = [];
	
	public newSidebarOpen(_page,_sidebarvar) {
		this.activeSidebars.push({'page':_page,'var':_sidebarvar});
	}
	
	public sidebarClose(_page,_sidebarvar) {
		
		this.activeSidebars.forEach((element,index)=>{
		   if(element.page==_page && element.var==_sidebarvar) this.activeSidebars.splice(index,1);
		});
	}
	
	public closeActiveSidebar(){
		if (this.activeSidebars.length > 0){
			this.activeSidebars[this.activeSidebars.length-1].page[this.activeSidebars[this.activeSidebars.length-1].var] = false;
			this.activeSidebars.splice(this.activeSidebars.length-1,1);
		}
	}
}