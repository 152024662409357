import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login/login.component';
import { RegisterComponent } from './components/register/register/register.component';
import { OnetimepasswordComponent } from './components/register/onetimepassword/onetimepassword.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { AuthguardGuard } from './core/guards/authguard.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { BlankComponent } from './blank/blank.component';

const routes: Routes = [
  // { path: '', component: LoginComponent },
  {
    path: 'admin', canActivate: [AuthguardGuard],
    loadChildren: () => import('./components/super-admin/super-admin.module').then(m => m.SuperAdminModule),
  },
  {
    path: 'account', canActivate: [AuthguardGuard],
    loadChildren: () => import('./components/account-admin/account-admin.module').then(m => m.AccountAdminModule)
  },
  { path: 'register', component: RegisterComponent },
  { path: 'onetimepassword', component: OnetimepasswordComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },

  { path: '', component: BlankComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }