<div class="auth-page">
    <div class="container p-0">
        <div class="row g-0">
            <div class="col">
                <div class="auth-full-page-content p-sm-5 p-4">
                    <div class="w-100">
                        <div class="d-flex flex-column h-100">
                            <div class="mb-4 mb-md-5 text-center">
                                <a routerLink="/" class="d-block auth-logo">
                                    <span class="logo-txt">AutomatR</span>
                                </a>
                            </div>
                            <div class="auth-content my-auto">
                                <div class="text-center">
                                    <h5 class="mb-0">Forgotten Password ?</h5>
                                    <p class="text-muted mt-2">Enter your email to reset your password:</p>
                                </div>
                                <form class="d-flex flex-column align-items-center">
                                    <div class="row">
                                        <div class="col-xxl-12 col-lg-12 col-md-12">
                                            <div class="mb-3">
                                                <label for="email" class="col-sm-2 col-form-label">Email</label>
                                                <div class="col-sm-12">
                                                    <input type="email" class="form-control" id="email"
                                                        placeholder="Enter Email">
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <button class="btn btn-soft-primary float-start" routerLink="/login">
                                                    <i class="bx bx-left-arrow-alt"></i>
                                                </button>
                                                <button class="btn btn-soft-primary float-end">Reset Password</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>