import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthApiService } from 'src/app/core/services/auth/auth.service';
import { Globals } from 'src/app/globals/globals';
import { AuthService } from 'src/app/oidc/oauth/oauth.service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import {
	SearchCountryField,
	CountryISO
} from "ngx-intl-tel-input";

function phoneValidator(control: AbstractControl): { [key: string]: boolean } | null {
	const phoneRegex = /^\+(?:[0-9]\-?){6,14}[0-9]$/;

	if (control.value && !phoneRegex.test(control.value)) {
		return { invalidPhone: true };
	}

	return null;
}

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss'],
	providers: [MessageService, ConfirmationService]
})
export class RegisterComponent implements OnInit {

	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	preferredCountries: CountryISO[] = [CountryISO.Qatar];

	formSubmitted: boolean;
	checkPhone: boolean;
	countryName: any;
	registerForm: FormGroup;
	params: any;
	queryParamsSubscription: Subscription;
	checkContactPhone: boolean;
	showErrorAlready: boolean;
	constructor(private router: Router,
		private authApiService: AuthApiService,
		private formBuilder: FormBuilder,
		private messageService: MessageService,
		public globals: Globals
	) { }
	ngOnInit(): void {
		this.registerForm = this.formBuilder.group({
			companyName: ['', [Validators.required]],
			companyURL: ['', [Validators.required, Validators.pattern('^(https?:\\/\\/)?[\\w\\.-]+\\.[a-zA-Z]{2,}(\\/\\S*)?$')]],

			email: ['', [Validators.required, Validators.email]],
			phone: ['', [Validators.required]],
			country: ['', [Validators.required]],
			address: [''],
			contactName: ['', [Validators.required]],
			contactEmail: ['', [Validators.required, Validators.email]],
			contactPhone: ['', [Validators.required]],
			IsFreeTrialActivated: [''],
		});


		this.authApiService.getCountries().subscribe((res) => {
			this.countryName = res.map((res) => res.name)
		});
	}

	onFinalUserName(value) {
		this.showErrorAlready = false;
		if (value != '') {
			this.authApiService.checkUserName(value).subscribe((res) => {
				const flag = res.flag;
				const dataRes = res.data;
				if (!flag && (dataRes?.isEmailVerified && dataRes?.isPhoneVerified) == true) {
					this.showErrorAlready = true;
				} else if (!flag && (dataRes?.isEmailVerified && dataRes?.isPhoneVerified) == false) {

					Swal.fire({
						title: 'Registration is pending',
						text: 'Click Yes to navigate for verifying OTPs!',
						icon: 'warning',
						showCancelButton: true,
						cancelButtonColor: '#f46a6a',
						confirmButtonColor: '#34c38f',
						confirmButtonText: 'Yes',
						allowEscapeKey: false,
						allowOutsideClick: false,
					}).then(result => {
						if (result.value) {
							const data = {
								id: dataRes.customerGuid,
								registaionStatus: false,
								emailVerified: dataRes.isEmailVerified,
								phoneVerified: dataRes.isPhoneVerified,
								isPasswordRequired: dataRes.isPasswordRequired
							};
							this.router.navigate(['/onetimepassword'], { state: { data } });
						} else {
							this.registerForm.controls['email'].reset()
						}
					});
				}
			});
		}
	}


	onSubmit() {
		this.registerForm.value.phone = this.registerForm.value.phone?.e164Number ? this.registerForm.value.phone?.e164Number : this.registerForm.value.phone;
		if (this.registerForm.controls['phone'].status == 'INVALID') {
			this.checkPhone = true;
		} else {
			this.checkPhone = false;
		}

		this.registerForm.value.contactPhone = this.registerForm.value.contactPhone?.e164Number ? this.registerForm.value.contactPhone?.e164Number : this.registerForm.value.contactPhone;
		if (this.registerForm.controls['contactPhone'].status == 'INVALID') {
			this.checkContactPhone = true;
		} else {
			this.checkContactPhone = false;
		}
		
		if (this.registerForm.valid && !this.showErrorAlready) {
			this.formSubmitted = false;
			this.checkPhone = false;
			this.checkContactPhone = false;
			this.registerForm.value.IsFreeTrialActivated = true;
			this.authApiService.creatAccount(this.registerForm.value)?.subscribe((res) => {
				if (res.flag) {
					const dataRes = res.data;
					this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
					const data = {
						id: dataRes.customerGuid,
						registaionStatus: true,
						emailVerified: dataRes.isEmailVerified,
						phoneVerified: dataRes.isPhoneVerified,
						isPasswordRequired: dataRes.isPasswordRequired
					};
					this.router.navigate(['/onetimepassword'], { state: { data } });
				} else {
					this.messageService.add({
						severity: 'warn',
						summary: 'Warning',
						detail: res.message,
					});
				}
			});
		} else {
			this.formSubmitted = true;
		}
	}

	goHome() {
		this.authApiService.navigateToHome();
	}


	onCopyToContact() {
		if (this.registerForm.get('companyName').value) {
			this.registerForm.get('contactName').patchValue(this.registerForm.get('companyName').value);
		}
		if (this.registerForm.get('email').value) {
			this.registerForm.get('contactEmail').patchValue(this.registerForm.get('email').value);
		}
		if (this.registerForm.get('phone').value) {
			this.registerForm.get('contactPhone').patchValue(this.registerForm.get('phone').value);
		}
	}


}
