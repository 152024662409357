<div id="layout-wrapper">
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/Automatr_icon.svg" alt="Logo" class="logo_size" />
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/Automatr_logo.svg" class="logo_size" alt="Logo" />
                        </span>
                    </a>
                </div>

                <button type="button" (click)="toggleMobileMenu()" class="btn btn-sm px-3 font-size-16 header-item"
                    id="vertical-menu-btn">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </div>
            <button type="button" class="btn btn-primary me-2"  style="margin-left: auto;" (click)="selectHub()">
                Hubs
            </button>
            <div class="d-flex">
                <div ngbDropdown class="dropdown d-inline-block">
                    <button type="button" class="btn header-item bg-soft-light border-start border-end"
                        id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" ngbDropdownToggle>
                        <p-avatar shape="circle">
                            {{nameSingleValue | uppercase}}
                        </p-avatar>
                        <span class="d-none d-xl-inline-block ms-1 fw-medium">{{userName}}</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <a class="dropdown-item cursor-pointer" (click)="changePsw()"><i
                                class="mdi mdi-lock font-size-16 align-middle me-1"></i> Change Password</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item cursor-pointer" (click)="onLogout()">
                            <i class="bx bx-log-out-circle font-size-16 align-middle me-1"></i>Log Out
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <!-- ========== Left Sidebar Start ========== -->
    <div class="vertical-menu">
        <ngx-simplebar class="h-100">
            <!--- Sidemenu -->
            <div id="sidebar-menu">
                <!-- Left Menu Start -->
                <ul class="metismenu list-unstyled" id="side-menu">
                    <li routerLink="profile" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
                        <a>
                            <i class="dripicons-user"></i>
                            <span data-key="t-dashboard">Profile</span>
                        </a>
                    </li>
                    <li routerLink="billing" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
                        <a>
                            <i class="bx bx-detail"></i>
                            <span data-key="t-dashboard">Billing</span>
                        </a>
                    </li>
                    <li routerLink="hubs" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
                        <a>
                            <i class="dripicons-graph-pie"></i>
                            <span data-key="t-dashboard">Hubs</span>
                        </a>
                    </li>

                    <li routerLink="licenses" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
                        <a>
                            <i class="bx bx-badge-check"></i>
                            <span data-key="t-dashboard">Licenses</span>
                        </a>
                    </li>

                    <li routerLink="downloads" routerLinkActive="mm-active" [routerLinkActiveOptions]="{exact: true}">
                        <a>
                            <i class="mdi mdi-folder-download-outline"></i>
                            <span data-key="t-dashboard">Downloads</span>
                        </a>
                    </li>

                </ul>
            </div>
            <!-- Sidebar -->
        </ngx-simplebar>
    </div>
    <!-- Left Sidebar End -->


    <div class="main-content" id="mainContent">
        <div class="page-content">
            <div class="container-fluid">
                <!-- content -->
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- footer -->
        <!-- <app-footer></app-footer> -->
    </div>
</div>