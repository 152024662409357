<p-toast position="top-right"></p-toast>
<div class="auth-page">
  <div class="container">
    <div class="row">
      <div class=" centered-card">
        <div class="card shadow">
          <div class="card-header">
            <img src="../../../../assets//images/Automatrwhite.svg">
          </div>
          <div class="card-body">
            <div class="container">
              <h5 class="card-title">Verification</h5>
              <div class="row">
                <div class="col-xxl-12 col-lg-12 col-md-12">
                  <div class="mb-3" *ngIf="!emailVerified">
                    <label>Email OTP <span class="required">*</span> <span class="group-button"
                        *ngIf="emailCounterShow">Expires in {{
                        emailFormatTime(emailMinutes) }}:{{ emailFormatTime(emailSeconds) }}</span></label>
                    <ngx-otp-input [config]="otpInputConfig" (otpChange)="changemailOtp($event)"
                      (fill)="emailOtp($event)"></ngx-otp-input>
                    <p *ngIf="!resendEmailOTPDone">Don't receive the email OTP? <a class="required cursor-pointer"
                        (click)="emailResendOTP()">Resend OTP</a></p>
                    <p *ngIf="resendEmailOTPDone">Please check email for OTP <a class="text-success">OTP Sent <i
                          class="bx bx-check-circle"></i></a></p>
                  </div>


                  <div class="mb-3" *ngIf="!phoneVerified">
                    <label>Phone OTP <span class="required">*</span> <span class="group-button"
                        *ngIf="phoneCounterShow">Expires in
                        {{phoneFormatTime(phoneMinutes) }}:{{ phoneFormatTime(phoneSeconds) }} </span></label>
                    <ngx-otp-input [config]="otpInputConfig" (otpChange)="changMobileOtp($event)"
                      (fill)="mobileOtp($event)"></ngx-otp-input>
                    <p *ngIf="!resendPhoneOTPDone">Don't receive the phone OTP? <a class="required cursor-pointer"
                        (click)="mobileResendOTP()">Resend OTP</a></p>
                    <p *ngIf="resendPhoneOTPDone">Please check phone for OTP <a class="text-success">OTP Sent <i
                          class="bx bx-check-circle"></i></a></p>
                  </div>
                  <span class="group-button branding-text cursor-pointer mb-2" (click)="onChangePassword()">Change
                    Phone</span>

                  <form [formGroup]="changeNumberForm" (ngSubmit)="onSubmit()" *ngIf="showChangePassword">
                    <div class="pb-1">
                      <label for="phone">Phone Number <span class="required">*</span></label><br>
                      <ngx-intl-tel-input [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField?.Name]"
                        [selectFirstCountry]="false" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="true"
                        [enablePlaceholder]="true" #phoneNumberInput name="phone" formControlName="phone">
                      </ngx-intl-tel-input><br>
                      <span *ngIf="checkPhone && this.changeNumberForm.controls['phone'].invalid" class="required">
                        Please
                        provide a valid phone number</span>
                    </div>
                    <div class="group-button">
                      <button class="btn btn-sm btn-soft-primary mb-3 me-2" type="button"
                        (click)="onClear()">Close</button>
                      <button class="btn btn-sm btn-primary mb-3" type="submit">Update</button>
                    </div>
                  </form>

                  <div class="mb-3" *ngIf="isPasswordRequired">
                    <label>Password <span class="required">*</span></label><br>
                    <p-password [(ngModel)]="password" (paste)="onPaste($event)" [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}" name="password" placeholder="Enter Password" ngDefaultControl
                      [toggleMask]="true" [feedback]="true" [ngModelOptions]="{ standalone: true }"></p-password>
                  </div>

                  <div class="mb-3" *ngIf="isPasswordRequired">
                    <label>Confirm Password <span class="required">*</span></label><br>
                    <p-password [(ngModel)]="cpassword" (paste)="onPaste($event)" [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}" placeholder="Enter Confirm Password" name="cpassword"
                      ngDefaultControl [toggleMask]="true" [feedback]="false"
                      [ngModelOptions]="{ standalone: true }"></p-password>
                  </div>
                  <div class="mb-3">
                    <p *ngIf="checkPassword" class="required">Password and Confirmation password should match</p>
                    <p *ngIf="requiredEmailOTPS" class="required">Please Enter Email OTP</p>
                    <p *ngIf="requiredPhoneOTPS" class="required">Please Enter Phone OTP</p>
                    <p *ngIf="requiredPassword" class="required">Please enter password and confirmation password</p>
                    <button class="btn btn-primary full-width-button" (click)="onVerify()">Verify </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>