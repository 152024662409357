import { Component, OnInit } from '@angular/core';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { AuthApiService } from 'src/app/core/services/auth/auth.service';
import { MessageService } from 'primeng/api';
import { Subscription, interval, take, timer } from 'rxjs';
import Swal from 'sweetalert2';


import {
  SearchCountryField,
  CountryISO
} from "ngx-intl-tel-input";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-onetimepassword',
  templateUrl: './onetimepassword.component.html',
  styleUrls: ['./onetimepassword.component.scss'],
  providers: [MessageService]
})
export class OnetimepasswordComponent implements OnInit {
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Qatar];

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  emailFilledOtp: any;
  mobileFilledOtp: any;
  registerId: number;
  resendEmailOTPDone: boolean = false;
  resendPhoneOTPDone: boolean;
  requiredEmailOTPS: boolean;
  requiredPhoneOTPS: boolean;

  password: string;
  cpassword: string;
  // confirmError: string = " "
  requiredPassword: boolean;
  checkPassword: boolean;


  registaionStatus: boolean;

  changeNumberForm: FormGroup;
  checkPhone: boolean;
  formSubmitted: boolean;
  showChangePassword: boolean;

  emailMinutes: number = 5;
  emailSeconds: number = 0;

  phoneMinutes: number = 5;
  phoneSeconds: number = 0;

  emailSubscription: Subscription;
  phoneSubscription: Subscription;
  emailCounterShow: boolean;
  phoneCounterShow: boolean;
  phoneVerified: boolean;
  emailVerified: boolean;
  isPasswordRequired: boolean;


  constructor(private authApiService: AuthApiService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }
  ngOnInit(): void {
    this.emailCounterShow = true;
    this.phoneCounterShow = true;
    this.changeNumberForm = this.formBuilder.group({
      phone: ['', [Validators.required]],
    });

    if (window.history.state.data?.id) {
      this.registerId = window.history.state.data?.id
    } else {
      this.authApiService.navigateToHome();
    }

    this.registaionStatus = window.history.state.data?.registaionStatus;
    this.emailVerified = window.history.state.data?.emailVerified;
    this.phoneVerified = window.history.state.data?.phoneVerified;
    this.isPasswordRequired = window.history.state.data?.isPasswordRequired;

    if (!this.registaionStatus) {
      if (!this.emailVerified) {
        this.emailResendOTP();
        setTimeout(() => {
          this.emailCountdown();
        }, 1000);
      }
      if (!this.phoneVerified) {
        this.mobileResendOTP();
        setTimeout(() => {
          this.phoneCountdown();
        }, 1000);
      }

    } else {
      this.emailCountdown();
      this.phoneCountdown();
    }
  }


  emailOtp(value: string): void {
    this.emailFilledOtp = value;
  }

  changemailOtp(value: any): void {
    this.emailFilledOtp = value.join().replace(/,/g, "");
  }

  mobileOtp(value: string): void {
    this.mobileFilledOtp = value;
  }

  changMobileOtp(value: any): void {
    this.mobileFilledOtp = value.join().replace(/,/g, "");
  }

  onVerify(): void {
    this.requiredEmailOTPS = false;
    this.requiredPhoneOTPS = false;
    this.requiredPassword = false;
    this.checkPassword = false;

    if (!this.emailVerified) {
      if (this.emailFilledOtp?.length != 6) {
        this.requiredEmailOTPS = true;
      }
    }

    if (!this.phoneVerified) {
      if (!this.phoneVerified) {
        if (this.mobileFilledOtp?.length != 6) {
          this.requiredPhoneOTPS = true;
        }
      }
    }

    if (this.isPasswordRequired) {
      if (!this.password || !this.cpassword) {
        this.requiredPassword = true;
      }

      if (this.password !== this.cpassword) {
        this.checkPassword = true;
      }
    }

    // if (!this.registaionStatus) {
    //   if (!this.emailVerified) {
    //     if (this.emailFilledOtp?.length != 6) {
    //       this.requiredEmailOTPS = true;
    //     }
    //   }
    //   if (!this.phoneVerified) {
    //     if (this.mobileFilledOtp?.length != 6) {
    //       this.requiredPhoneOTPS = true;
    //     }
    //   }

    // } else {
    //   if (this.emailFilledOtp?.length != 6) {
    //     this.requiredEmailOTPS = true;
    //   }
    //   if (this.mobileFilledOtp?.length != 6) {
    //     this.requiredPhoneOTPS = true;
    //   }
    // }


    if (!this.checkPassword && !this.requiredPassword && !this.requiredEmailOTPS && !this.requiredPhoneOTPS) {
      const data = {
        customerGuid: this.registerId,
        emailOtp: this.emailFilledOtp,
        phoneOtp: this.mobileFilledOtp,
        password: this.password
      };
      this.authApiService.verifyPhoneEmail(data).subscribe((res) => {
        if (res.flag) {
          this.emailFilledOtp = '';
          this.mobileFilledOtp = '';
          Swal.fire({
            allowOutsideClick: false,
            title: 'Sending you to the login page',
            text: res.message,
            icon: 'success',
            allowEscapeKey: false,
            showCancelButton: false,
            confirmButtonColor: '#34c38f',
            cancelButtonColor: '#f46a6a',
          }).then((result) => {
            if (result.value) {
              this.authApiService.navigateToHome();
            }
          });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: res.message
          });
        }
      });
    }

  }


  emailResendOTP() {
    const data = { "customerGuid": this.registerId };

    this.authApiService.resendEmail(data).subscribe((res) => {
      if (res.flag) {
        this.emailCounterShow = true;
        this.resendEmailOTPDone = true;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
        this.resetEmailCountdown();
      } else {
        this.emailCounterShow = false
        this.resendEmailOTPDone = false;
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: res.message,
        });
      }
      setTimeout(() => {
        this.resendEmailOTPDone = false;
      }, 3000);
    });
  }

  mobileResendOTP() {
    const data = { "customerGuid": this.registerId };

    this.authApiService.resendMobile(data).subscribe((res) => {
      if (res.flag) {
        this.phoneCounterShow = true;
        this.resendPhoneOTPDone = true;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
        this.resetPhoneCountdown();
      } else {
        this.phoneCounterShow = false;
        this.resendPhoneOTPDone = false;
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning',
          detail: res.message,
        });
      }
      setTimeout(() => {
        this.resendPhoneOTPDone = false;
      }, 3000)
    });
  }

  resetEmailCountdown() {
    this.emailSubscription?.unsubscribe();
    this.emailMinutes = 5;
    this.emailSeconds = 0;
    this.emailCountdown();
  }

  resetPhoneCountdown() {
    this.phoneSubscription?.unsubscribe();
    this.phoneMinutes = 5;
    this.phoneSeconds = 0;
    this.phoneCountdown();
  }



  onPaste(event: ClipboardEvent) {
    event.preventDefault();
  }

  onSubmit() {
    this.changeNumberForm.value.phone = this.changeNumberForm.value.phone?.e164Number;
    if (this.changeNumberForm.controls['phone'].status == 'INVALID') {
      this.checkPhone = true;
    } else {
      this.checkPhone = false;
    }


    if (this.changeNumberForm.valid) {
      this.formSubmitted = false;
      this.checkPhone = false;
      const phone = this.changeNumberForm.get('phone').value.e164Number
      const data = { 'customerGuid': this.registerId, 'phone': phone }
      this.authApiService.changePhone(data).subscribe((res) => {
        if (res.flag) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: res.message });
          this.onClear();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning',
            detail: res.message,
          });
        }
      });
    } else {
      this.formSubmitted = true;
    }
  }

  onChangePassword() {
    this.showChangePassword = this.showChangePassword ? false : true
  }

  onClear() {
    this.showChangePassword = false;
    this.changeNumberForm.reset()
  }


  emailCountdown() {
    const countdown = interval(1000);
    this.emailSubscription = countdown.subscribe(() => {
      if (this.emailMinutes === 0 && this.emailSeconds === 0) {
        this.emailCounterShow = false;
        this.emailSubscription.unsubscribe();
      } else {
        if (this.emailSeconds === 0) {
          this.emailMinutes--;
          this.emailSeconds = 59;
        } else {
          this.emailSeconds--;
        }
      }
    });
  }

  phoneCountdown() {
    const countdown = interval(1000);
    this.phoneSubscription = countdown.subscribe(() => {
      if (this.phoneMinutes === 0 && this.phoneSeconds === 0) {
        this.phoneCounterShow = false;
        this.phoneSubscription.unsubscribe();
      } else {
        if (this.phoneSeconds === 0) {
          this.phoneMinutes--;
          this.phoneSeconds = 59;
        } else {
          this.phoneSeconds--;
        }
      }
    });
  }

  emailFormatTime(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }

  phoneFormatTime(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }

  ngOnDestroy() {
    this.emailSubscription?.unsubscribe();
    this.phoneSubscription?.unsubscribe();
  }

}
