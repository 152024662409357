import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Globals } from 'src/app/globals/globals';
import { AuthService } from 'src/app/oidc/oauth/oauth.service';

@Component({
  selector: 'app-super-admin',
  templateUrl: './super-admin.component.html',
  styleUrls: ['./super-admin.component.scss']
})
export class SuperAdminComponent implements OnInit {
  @Output() mobileMenuButtonClicked = new EventEmitter();

  nameSingleValue: any;
  userName: any;

  selectedCity: string;
  cities: { name: string; code: string; }[];
  isCondensed: boolean;

  constructor(private authService: AuthService) {
  }

  toggleMobileMenu() {
    document.body.classList.toggle('sidebar-enable');
    const currentSIdebarSize = document.body.getAttribute("data-sidebar-size");
    if (window.screen.width >= 992) {
      if (currentSIdebarSize == null) {
        (document.body.getAttribute('data-sidebar-size') == null || document.body.getAttribute('data-sidebar-size') == "lg") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'lg')
      } else if (currentSIdebarSize == "md") {
        (document.body.getAttribute('data-sidebar-size') == "md") ? document.body.setAttribute('data-sidebar-size', 'sm') : document.body.setAttribute('data-sidebar-size', 'md')
      } else {
        (document.body.getAttribute('data-sidebar-size') == "sm") ? document.body.setAttribute('data-sidebar-size', 'lg') : document.body.setAttribute('data-sidebar-size', 'sm')
      }
    }
    this.isCondensed = !this.isCondensed;
  }

  ngOnInit(): void {
    this.userName = JSON.parse(sessionStorage.getItem('id_token_claims_obj')).email;
    this.nameSingleValue = this.userName.charAt(0);
  }

  changePsw() {
    this.authService.changePassword();
  }

  onLogout() {
    this.authService.logout();
    localStorage.clear();
  }
}
